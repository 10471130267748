.Creditpicker{
    background-color: rgba(255, 255, 255, 0.946);
    justify-content:center;
    align-items: center;
    height:20vw;
    text-align: center;
    width:42vw;
    border: 1px solid;
    border-radius: 10px;
    margin-left: 28%;

    
}
.Creditpicker h1{
    font-size: 1.5vw;
    color: #0C1844;
    padding-bottom: 2.5%;
    padding-top: 2.5%;
}
.brk{
    padding-left: 4%;
}
.field{
    display: flex;
    justify-content: center;
}