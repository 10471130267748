.container{
    background-color: black;
    display: flex;
    align-items: center;
    background-color: white;
    border: 1px solid #dcdcdc;
    border-radius: 20px;
    padding: 10px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    
    color: #555555;
    width:18vw;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    transition: background-color 0.3s, box-shadow 0.3s;
}

#googlesymbol{
    cursor: pointer;
    width: 3vw;
    height: 3vw;
    margin-right: 10px;
    

}
#signintext{
    cursor: pointer;
    font-size: 1.5vw;
    padding-left: 5%;
    color:black;

}