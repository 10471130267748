.Navbar-container {
    height: 7vw;
    display: flex;
}

.Navbar-container img {
    width: 3vw;
}

.Navbar-container h1 {
    font-size: 1.5vw;
    color: white;
}

.usericon {
    display: flex;
    align-items: center;
    padding-left: 1%    ;
    /* Vertically centers the items */
}

.usericon img {
    margin-right: 10px;
}

.usericon h1 {
    margin: 0;
}
.creditminutes{
    display:flex;
    margin-right: 1.5vw;
    align-items: center;
    justify-content: flex-end;
}
.creditminutes img{
    padding-left: 9%;
    margin-right: 30px;
}
.creditminutes h1{
    margin-left: 10px;

}
.logout{
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: flex-end;
    right: 0;

}
.right-items{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right:3%  ;
    margin-left: auto;
    
}