#Previous_ledger-container{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    place-items: center;
    width:50%;
    margin-left: 25%;
    padding-top: 0.5%;
    text-align: center;
    border: 1px solid;
    border-radius: 9px;
}

#account{
    margin-top: 3%;
    text-align: center;
    font-size: 4.5vw;
    color: white;
}
#ledger-text{
    margin-top: 3%;
    text-align: center;
    font-size: 4.5vw;
    color: white;
}