#Previousupload-container{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    place-items: center;
    width:65%;
    margin-left: 18%;
    padding-top: 0.5%;
    text-align: center;
    border: 1px solid;
    border-radius: 9px;
}
