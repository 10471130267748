@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');



.App {
  text-align: center;
  position: relative;
  margin-top: 18%;
  overflow-y: none;

}

.Appheader h1{
  color: white;
  font-size: 5vw;
  font-family: 'Poppins',serif;
  font-weight: 200;
  animation: slideInFromTop 1s ease forwards;

}


.googlesign{
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: slideInFromBottom 2s ease forwards;

}
#vector-left{
  position: absolute;
  top:-50%;
  left:0;
  pointer-events: none;
  z-index: 0;

}
#vector-right{
  position: absolute;
  top:-50%;
  right: 0;
  z-index: 0;
  pointer-events: none;
  transform: scaleX(-1)
}


@keyframes slideInFromTop {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideInFromBottom {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.animate-from-top {
  animation: slideInFromTop 1s ease forwards;
}

.animate-from-bottom {
  animation: slideInFromBottom 1s ease forwards;
}