@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');


.Navbar2-container{
    display:flex;
    justify-content: center;
}
.Navbar2-container h1{
    font-size: 2.6vw;
    color:white;
    font-family: 'Poppins',serif;
    font-weight: 400;
}
.Home:hover h1{
    color:#FF6969;
}
.Account:hover h1{
    color:#FF6969;
}
.ContactUs:hover h1{
    color:#FF6969;
}
.Upload:hover h1{
    color:#FF6969;
}
.Home{
    margin-right: 3%;
    cursor: pointer;
    

}
.Account{
    margin-right: 3%;
    cursor: pointer;


}
.ContactUs{
    cursor: pointer;
}
.Upload{
    margin-right: 3%;
    cursor: pointer;


}